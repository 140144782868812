import { Col, ConfigProvider, InputNumber, Row } from "antd";
import React from "react";
import Text from "../Text";
import { priceToTick } from "../../utils/poolFunctions";
import { FeeAmount, TICK_SPACINGS } from "@uniswap/v3-sdk";
import { useAccount } from "wagmi";

interface PriceRangeProp {
  colorchild?: string;
  colorParent?: string;
  handlelowPriceRangeChange: (price: number | null) => void;
  lowPriceRange: null | number;
  handleHighPriceRangeChange: (price: number | null) => void;
  highPriceRange: null | number;
  token0Symbol: string;
  token1Symbol: string;
  token0Decimals: number;
  token1Decimals: number;
  fee: FeeAmount;
}

const PriceRange = (props: PriceRangeProp) => {
  const {
    colorchild,
    colorParent,
    handlelowPriceRangeChange,
    lowPriceRange,
    highPriceRange,
    handleHighPriceRangeChange,
    token0Symbol,
    token1Symbol,
    token0Decimals,
    token1Decimals,
    fee,
  } = props;

  return (
    <Row
      style={{
        width: "100%",
        flexDirection: "column",
        gap: "6px",
        // padding: "12px",
        borderRadius: "14px",
        backgroundColor: colorParent ? colorParent : "",
      }}
    >
      <Text size="sm" style={{ fontWeight: "500", width: "100%" }}>
        Set price range
      </Text>
      <Row style={{ width: "100%", gap: "16px" }}>
        <RangeInput
          title="Low price"
          color={colorchild}
          handlePriceRangeChange={handlelowPriceRangeChange}
          priceRange={lowPriceRange}
          token0Symbol={token0Symbol}
          token1Symbol={token1Symbol}
          token0Decimals={token0Decimals}
          token1Decimals={token1Decimals}
          fee={fee}
        />
        <RangeInput
          title="High price"
          color={colorchild}
          priceRange={highPriceRange}
          handlePriceRangeChange={handleHighPriceRangeChange}
          token0Symbol={token0Symbol}
          token1Symbol={token1Symbol}
          token0Decimals={token0Decimals}
          token1Decimals={token1Decimals}
          fee={fee}
        />
      </Row>
    </Row>
  );
};

export default PriceRange;

interface RangeInputProps {
  title: string;
  color?: string;
  priceRange: null | number;
  handlePriceRangeChange: (price: number | null) => void;
  token0Symbol: string;
  token1Symbol: string;
  fee: FeeAmount;
  token0Decimals: number;
  token1Decimals: number;
}
const RangeInput = (props: RangeInputProps) => {
  const {
    title,
    color,
    priceRange,
    handlePriceRangeChange,
    fee,
    token0Decimals,
    token0Symbol,
    token1Decimals,
    token1Symbol,
  } = props;

  const onBlur = async () => {
    if (priceRange) {
      const tick = await priceToTick(
        priceRange,
        token0Decimals,
        token1Decimals,
        TICK_SPACINGS[fee],
        title
      );

      const price =
        Math.pow(1.0001, tick) / Math.pow(10, token1Decimals - token0Decimals);

      handlePriceRangeChange(parseFloat((1 / price).toFixed(5)));
    }
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            paddingInline: 8,
            colorBgContainer: color ? "rgba(124, 124, 130, 0.25)" : "#37373C",
          },
        },
      }}
    >
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          flex: "1",
          background: color ? color : "#252527",
          borderRadius: "8px",
          padding: "12px",
        }}
      >
        <Text size="xs" style={{ color: "#9B9CA3" }}>
          {title}
        </Text>
        <InputNumber
          style={{ width: "100%", maxWidth: "160px" }}
          value={priceRange}
          onChange={handlePriceRangeChange}
          onBlur={onBlur}
        />
        <Text size="xs" style={{ color: "#9B9CA3" }}>
          {token1Symbol} Per {token0Symbol}
        </Text>
      </Col>
    </ConfigProvider>
  );
};
