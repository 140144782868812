import { Token, NativeCurrency as NativeC } from "@uniswap/sdk-core";

const CHAIN_INFO: any = {
  1: {
    wrappedNativeCurrency: {
      decimals: 18,
      name: "Ether",
      symbol: "ETH",
    },
  },
  11155111: {
    wrappedNativeCurrency: {
      decimals: 18,
      name: "Sepolia Ether",
      symbol: "sETH",
      address: "0x01575f7f219a5e2e14295de7f132fb56eb346c7c",
    },
  },
  9007: {
    wrappedNativeCurrency: {
      decimals: 18,
      name: "SHIDO",
      symbol: "WSHIDO",
      address: "0x2c9508639e6B901e2Bb1e51115b11A93eDc1CdD9",
    },
  },
};

export function wrappedNativeCurrency(chainId: any): Token {
  const wrappedCurrencyInfo = CHAIN_INFO[chainId].wrappedNativeCurrency;
  return new Token(
    chainId,
    wrappedCurrencyInfo.address,
    wrappedCurrencyInfo.decimals,
    wrappedCurrencyInfo.symbol,
    wrappedCurrencyInfo.name
  );
}

export class NativeCurrency implements NativeC {
  constructor(chainId: any) {
    const chainInfo = CHAIN_INFO[chainId];
    if (!chainInfo) {
      throw new Error("Native currency info not found");
    }

    this.chainId = chainId;
    this.decimals = chainInfo.wrappedNativeCurrency.decimals;
    this.name = chainInfo.wrappedNativeCurrency.name;
    this.symbol = chainInfo.wrappedNativeCurrency.symbol;
    this.isNative = true;
    this.isToken = false;
    this.address = chainInfo.address;
  }

  chainId: any;
  decimals: number;
  name: string;
  symbol: string;
  isNative: true;
  isToken: false;
  address: string;

  equals(currency: any): boolean {
    return currency.isNative && currency.chainId === this.chainId;
  }

  public get wrapped(): Token {
    return wrappedNativeCurrency(this.chainId);
  }

  private static _cachedNativeCurrency: { [chainId: number]: NativeCurrency } =
    {};

  public static onChain(chainId: number): NativeCurrency {
    return (
      this._cachedNativeCurrency[chainId] ??
      (this._cachedNativeCurrency[chainId] = new NativeCurrency(chainId))
    );
  }
}
