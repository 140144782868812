import axiosClient, { AxiosInstance } from "axios";
import { store } from "../redux/store";

const TIMEOUT = 35000;
const SERVER_URL =
  process.env.REACT_APP_API_HOST || "https://api.shido-pool.baboons.tech/api";
const axios: AxiosInstance = axiosClient.create({
  baseURL: SERVER_URL,
  headers: {
    "Content-Type": "application/json",
  },

  timeout: TIMEOUT,
});

axios.interceptors.request.use(
  async (config) => {
    const token =
      store.getState()?.user?.token || localStorage.getItem("accessToken");
    if (token && config?.headers) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    console.log("err response in intercept resp", err);
    return Promise.reject(err);
  }
);

export default axios;
