import { prepareWriteContract, readContract, writeContract } from "@wagmi/core";

interface ContractMethodParams {
  contractAddress: any;
  contractABI: any[];
  functionName: string;
  args?: any[];
  fromAddress: any;
  gas?: bigint;
  gasPrice?: bigint;
  value?: bigint;
}

export async function callContractMethod({
  contractAddress,
  contractABI,
  functionName,
  args = [],
  fromAddress,
  gas,
  gasPrice,
  value,
}: ContractMethodParams) {
  const data = {
    address: contractAddress,
    abi: contractABI,
    functionName,
    args,
    account: fromAddress,
    gas,
    gasPrice,
    value,
  };

  if (gas) {
    data["gas"] = gas;
  }

  if (gasPrice) {
    data["gasPrice"] = gasPrice;
  }

  if (value) {
    data["value"] = value;
  }

  const config = await prepareWriteContract(data);

  const response = await writeContract(config);
  return response;
}

interface ContractReadParams {
  address: any;
  abi: any[];
  functionName: string;
  args?: any[];
  fromAddress: any;
}

export async function callContractReadMethod({
  address,
  abi,
  functionName,
  args = [],
  fromAddress,
}: ContractReadParams) {
  const result = await readContract({
    address,
    abi,
    functionName,
    args,
    account: fromAddress,
  });
  return result;
}
