import { Button, Col, Layout, Row } from "antd";
import Text from "../components/Text";
import Mdinbox from "../assets/Svg/MdInbox.svg";
import FiArrow from "../assets/Svg/FiArrowUpRight.svg";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useEffect, useState } from "react";
import YourLiquidityModal from "../components/YourLiquidityModal";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { POSITION_MANAGER_ADDRESS } from "../constants";
import PostionManagerAbi from "../abis/positionManager.json";
import BigNumber from "bignumber.js";
import { erc20ABI } from "wagmi";
import { getPoolAddress, getPoolData } from "../utils/poolFunctions";
import UniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";
import { getTokenImage } from "../api/ApiCalls";
import { Spin } from "antd";
import { getTokenAmounts } from "../utils/poolFunctions";
import { useError } from "../contexts/ErrorContext";

const MyPositions = () => {
  const { connector, address } = useAccount();
  const [positions, setPositions] = useState<any>([]);
  const [positionIds, setPositionIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { setError } = useError();

  async function getTokenInfo(tokenAddress: any, info: any, web3: Web3) {
    let result;

    try {
      const tokenContract = new web3.eth.Contract(erc20ABI, tokenAddress);

      switch (info) {
        case "symbol":
          result = await tokenContract.methods.symbol().call();
          break;
        case "decimals":
          result = await tokenContract.methods.decimals().call();
          break;
        default:
          throw new Error("Invalid token info type");
      }

      return result;
    } catch (error) {
      console.error(`Error fetching token ${info}:`, error);

      return null;
    }
  }

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const web3 = new Web3(await connector?.getProvider());
      const contract = new web3.eth.Contract(
        PostionManagerAbi,
        POSITION_MANAGER_ADDRESS
      );

      const numPositions = await contract.methods.balanceOf(address).call();

      const positionIds: any = await Promise.all(
        Array.from({ length: Number(numPositions) }, (_, i) =>
          contract.methods.tokenOfOwnerByIndex(address, i).call()
        )
      );

      setPositionIds(positionIds);

      const positionCalls = positionIds.map((id: any) =>
        contract.methods.positions(id).call()
      );
      const callResponses = await Promise.all(positionCalls);

      const positionInfos = await Promise.all(
        callResponses.map(async (position: any, index) => {
          const {
            tickLower,
            tickUpper,
            liquidity,
            feeGrowthInside0LastX128,
            feeGrowthInside1LastX128,
            tokensOwed0,
            tokensOwed1,
            token0,
            token1,
            fee,
          } = position;

          const [
            poolAddress,
            token0Symbol,
            token1Symbol,
            token0Decimals,
            token1Decimals,
          ]: any = await Promise.all([
            getPoolAddress(address, token0, token1, fee),
            getTokenInfo(token0, "symbol", web3),
            getTokenInfo(token1, "symbol", web3),
            getTokenInfo(token0, "decimals", web3),
            getTokenInfo(token1, "decimals", web3),
          ]);

          const poolContract = new web3.eth.Contract(
            UniswapV3Pool.abi,
            poolAddress
          );
          const poolData = await getPoolData(poolContract);

          const liquidityPercentage = BigNumber(liquidity)
            .div(BigNumber(poolData.liquidity))
            .multipliedBy(BigNumber(100));

          const [token0image, token1image, [pooledToken0, pooledToken1]] =
            await Promise.all([
              getTokenImage(token0),
              getTokenImage(token1),
              getTokenAmounts(
                Number(liquidity),
                BigNumber(poolData.sqrtPriceX96).toString(),
                Number(tickLower),
                Number(tickUpper),
                Number(token0Decimals),
                Number(token1Decimals)
              ),
            ]);

          return {
            tickLower,
            tickUpper,
            liquidity: BigNumber(liquidity),
            feeGrowthInside0LastX128: BigNumber(feeGrowthInside0LastX128),
            feeGrowthInside1LastX128: BigNumber(feeGrowthInside1LastX128),
            tokensOwed0: BigNumber(tokensOwed0),
            tokensOwed1: BigNumber(tokensOwed1),
            token0,
            token1,
            fee,
            positionIdIndex: index,
            liquidityPercentage,
            totalLiquidity: poolData.liquidity,
            token0Symbol,
            token1Symbol,
            token0Decimals,
            token1Decimals,
            poolSqrtPrice: poolData.sqrtPriceX96,
            pooledToken0,
            pooledToken1,
            token0image,
            token1image,
          };
        })
      );

      setPositions(positionInfos);
      setLoading(false);
    };

    if (address && connector) {
      loadData();
    }
  }, [connector, address]);

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Layout
      style={{
        padding: sm ? "36px" : "12px",
        backgroundColor: "#252527",
        borderRadius: "12px",
        marginTop: "20px",
        alignItems: "center",
      }}
    >
      <Row style={{ maxWidth: "1240px", width: "100%", gap: "24px" }}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Text size="lg">My Pools</Text>
        </Row>
        {/* <EmptyPositions /> */}
        <WithPosition
          positions={positions}
          positionIds={positionIds}
          loading={loading}
        />
        {/* <ArrowComponent /> */}
      </Row>
    </Layout>
  );
};

export default MyPositions;

const EmptyPositions = () => {
  return (
    <Row
      style={{
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        borderRadius: "20px",
        backgroundColor: "#37373C",
        padding: "24px",
      }}
    >
      <img src={Mdinbox} />
      <Text size="sm" style={{ color: "#9B9CA3", textAlign: "center" }}>
        This is where your open V3 liquidity positions will show up.
      </Text>
    </Row>
  );
};

const ArrowComponent = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  return (
    <Row
      style={{
        width: "100%",
        flexDirection: xs ? "column" : "row",
        gap: "24px",
      }}
    >
      <Col
        style={{
          flex: 1,
          padding: "24px",
          flexDirection: "column",

          borderRadius: "20px",
          backgroundColor: "#37373C",
        }}
      >
        <Row style={{ alignItems: "center", gap: "8px", marginBottom: "16px" }}>
          <Text size="sm" style={{ fontSize: "18px", fontWeight: 400 }}>
            Learn More
          </Text>
          <img src={FiArrow} />
        </Row>
        <Text size="sm" style={{ color: "#9B9CA3" }}>
          Lorem ipsum dolor sit amet consectetur. Id tortor amet platea pharetra
          elementum tincidunt feugiat neque.
        </Text>
      </Col>
      <Col
        style={{
          flex: 1,
          padding: "24px",
          flexDirection: "column",
          borderRadius: "20px",
          backgroundColor: "#37373C",
        }}
      >
        <Row style={{ alignItems: "center", gap: "8px", marginBottom: "16px" }}>
          <Text size="sm" style={{ fontSize: "18px", fontWeight: 400 }}>
            Fee Rewards
          </Text>
          <img src={FiArrow} />
        </Row>
        <Text size="sm" style={{ color: "#9B9CA3" }}>
          Lorem ipsum dolor sit amet consectetur. Id tortor amet platea pharetra
          elementum tincidunt feugiat neque.
        </Text>
      </Col>
    </Row>
  );
};

interface WithPositionInterface {
  positions: any;
  positionIds: any;
  loading: boolean;
}
const WithPosition = ({
  positions,
  positionIds,
  loading,
}: WithPositionInterface) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activePosition, setActivePosition] = useState({});

  return (
    <>
      <Row
        style={{
          width: "100%",
          flexDirection: "column",
          borderRadius: "20px",
          backgroundColor: "#37373C",
          padding: "24px",
        }}
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text size="lg">Your liquidity</Text>
        </Row>
        {loading ? (
          <Row
            style={{
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              gap: "30px",
              marginTop: "30px",
            }}
          >
            <Spin />
            <Text
              size="sm"
              style={{
                color: "#B6B7BF",
                maxWidth: "256px",
                textAlign: "center",
              }}
            >
              Currently we are fetching your liquidities, this may take some
              time...
            </Text>
          </Row>
        ) : (
          <Row style={{ width: "100%" }}>
            {positions.map((position: any) => (
              <LiquidityRow
                icon1={position.token0image}
                icon1Name={position.token0Symbol}
                icon2={position.token1image}
                icon2Name={position.token1Symbol}
                setState={setOpen}
                position={position}
                setActivePosition={setActivePosition}
                positionIds={positionIds}
              />
            ))}
          </Row>
        )}
      </Row>
      <YourLiquidityModal
        open={open}
        setState={setOpen}
        activePosition={activePosition}
        positionIds={positionIds}
      />
    </>
  );
};

interface LiquidityRowProps {
  icon1: string;
  icon2: string;
  icon1Name: string;
  icon2Name: string;
  position: any;
  setActivePosition: any;
  positionIds: any;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}
const LiquidityRow = (props: LiquidityRowProps) => {
  const {
    icon1,
    icon2,
    icon1Name,
    icon2Name,
    position,
    setActivePosition,
    setState,
  } = props;

  return (
    <Row
      style={{
        width: "100%",
        marginTop: "16px",
        paddingTop: "16px",
        borderTop: "1px solid rgba(255, 255, 255, 0.10)",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div style={{}}>
          <img
            src={icon1}
            style={{
              height: "24px",
              width: "24px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
          <img
            src={icon2}
            style={{
              height: "24px",
              width: "24px",
              position: "relative",
              left: "-5px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <Text size="sm">
          {icon1Name}/{icon2Name}
        </Text>
        <Text size="md" style={{ color: "#3B87F7" }}>
          {Number(position.fee) / 10000}%
        </Text>
      </Col>
      <Button
        type="primary"
        size="small"
        onClick={() => {
          setState(true);
          setActivePosition(position);
        }}
      >
        Manage
      </Button>
    </Row>
  );
};
