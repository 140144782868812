import Layout from "antd/es/layout/layout";
import React, { useEffect, useState, useCallback } from "react";
import CreatePool from "../components/CreatePool";
import { userState } from "../redux/reducers";
import { Col, Input, Row } from "antd";
import PoolCards, { DummyPoolCard } from "../components/PoolCards";
import { poolsState, setPools } from "../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useLazyQuery, useQuery } from "@apollo/client";
import { LOAD_POOLS, SEARCH_POOLS } from "../graphql";
import { debounce } from "lodash";

const Pools = () => {
  const user = useSelector(userState);
  const pools = useSelector(poolsState);
  const dispatch = useDispatch();
  const { sm, md, lg, xxl } = useBreakpoint();
  const { loading, data, refetch } = useQuery(LOAD_POOLS);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPools, { data: searchData, loading: searchLoading }] =
    useLazyQuery(SEARCH_POOLS);

  useEffect(() => {
    if (data) {
      dispatch(setPools(data.pools));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (searchData) {
      dispatch(setPools(searchData.pools));
    }
  }, [searchData, dispatch]);

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      if (term) {
        searchPools({ variables: { searchTerm: term.toLowerCase() } });
      } else if (data) {
        dispatch(setPools(data.pools));
      }
    }, 500),
    [searchPools, data, dispatch]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  return (
    <Layout
      style={{
        marginTop: "20px",
        backgroundColor: "#252527",
        borderRadius: "12px",
        padding: sm ? "36px" : "12px",
      }}
    >
      <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
        <h5
          style={{
            padding: 0,
            margin: 0,
            color: "white",
            fontSize: sm ? "32px" : "24px",
            fontWeight: "700",
          }}
        >
          Discover Pools
        </h5>
        <div style={{ width: sm ? "" : "100%", marginTop: sm ? "" : "16px" }}>
          {user.isAuthenticated && <CreatePool refetch={refetch} />}
        </div>
      </Row>
      <Row style={{ margin: "20px 0" }}>
        <Input
          size="large"
          placeholder="Search by token symbol , address or name."
          onChange={handleSearch}
          style={{ marginBottom: 20 }}
          value={searchTerm}
        />
      </Row>

      <Row style={{ marginTop: "24px" }} gutter={[16, 16]}>
        {pools.length > 0 && !loading && !searchLoading
          ? pools.map((pool, index) => (
              <Col key={index} span={xxl ? 6 : lg ? 8 : md ? 12 : 24}>
                <PoolCards Pool={pool} index={index} />
              </Col>
            ))
          : Array.from({ length: 3 }).map((_, index) => (
              <Col key={index} span={xxl ? 6 : lg ? 8 : md ? 12 : 24}>
                <DummyPoolCard />
              </Col>
            ))}
      </Row>
    </Layout>
  );
};

export default Pools;
