import { Button, Row } from "antd";
import herobg from "../../assets/HeroImagePNG.png";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../../components/Text";
import left from "../../assets/leftTubuler.png";
import right from "../../assets/rightTubuler.png";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const navigate = useNavigate();
  return (
    <Row
      style={{
        width: "100%",
        padding: xs ? "40px" : "70px",
        //backgroundImage: `url(${herobg})`,
        //backgroundSize: "cover",
        //backgroundPosition: "center",
        borderRadius: "12px",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        background: "linear-gradient(267deg, #4CA8F8 14.16%, #2B66F6 76.6%)",
        backgroundBlendMode: "normal, color",
        overflow: "hidden",
      }}
    >
      <img src={left} className="hero-left-image" />
      <img src={right} className="hero-right-image " />

      <Text
        size="xxl"
        style={{
          maxWidth: "830px",
          textAlign: "center",
          fontSize: xs ? "28px" : xl ? "42px" : "36px",
          zIndex: 2,
        }}
      >
        Unlock Earning Potential. Become a Liquidity Provider with{" "}
        <span style={{ color: "#071B33" }}>SHIDO</span> POOL
      </Text>
      <Text
        size="sm"
        style={{ textAlign: "center", marginTop: "16px", zIndex: 2 }}
      >
        Earn passive income by supplying liquidity to crypto projects. Secure,
        transparent, and user-friendly.
      </Text>

      <Button
        style={{
          backgroundColor: "#181819",
          borderRadius: "8px",
          border: "1px solid #181819",
          color: "white",
          fontSize: "16px",
          fontWeight: "600",
          marginTop: "20px",
          zIndex: 2,
        }}
        onClick={() => {
          navigate("/pools");
        }}
      >
        Explore Pools
      </Button>
    </Row>
  );
};

export default Hero;
