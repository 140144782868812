import { ethers } from "ethers";
import JSBI from "jsbi";
import {
  SWAP_ROUTER_V2,
  MAX_FEE_PER_GAS,
  MAX_PRIORITY_FEE_PER_GAS,
} from "../constants";
import { Token } from "@uniswap/sdk-core";
import { BigNumber } from "ethers";
import Web3 from "web3";

// //   import {
// //     getProvider,
// //     getWalletAddress,
// //     sendTransaction,
// //     TransactionState,
// //   } from "./providers";
import { fromReadableAmount } from "./tradeUtils";
import { erc20ABI } from "wagmi";
// import { getPoolData } from "./poolFunctions";
// import Web3 from "web3";
// import { erc20ABI } from "wagmi";
// const {
//   AlphaRouter,
//   SwapType,
// } = require("@vivektamilarasan/smart-order-router");

// export type TokenTrade = Trade<Token, Token, TradeType>;

// // Trading Functions

// export async function createTrade(
//   tokenIn: any,
//   tokenOut: any,
//   poolFee: any,
//   poolContract: any,
//   amountIn: any,
//   web3: Web3
// ): Promise<TokenTrade> {
//   const poolInfo = await getPoolData(poolContract);

//   const pool = new Pool(
//     tokenIn,
//     tokenOut,
//     poolFee,
//     poolInfo.sqrtPriceX96.toString(),
//     poolInfo.liquidity.toString(),
//     Number(poolInfo.tick)
//   );

//   const swapRoute = new Route([pool], tokenIn, tokenOut);

//   const amountOut = await getOutputQuote(
//     swapRoute,
//     tokenIn,
//     tokenOut,
//     amountIn,
//     web3
//   );

//   const uncheckedTrade = Trade.createUncheckedTrade({
//     route: swapRoute,
//     inputAmount: CurrencyAmount.fromRawAmount(
//       tokenIn,
//       fromReadableAmount(amountIn, Number(tokenIn.decimals)).toString()
//     ),
//     outputAmount: CurrencyAmount.fromRawAmount(
//       tokenOut,
//       JSBI.BigInt(amountOut)
//     ),
//     tradeType: TradeType.EXACT_INPUT,
//   });

//   //   await executeTrade(uncheckedTrade)

//   return uncheckedTrade;
// }

// export async function executeTrade(
//   trade: TokenTrade,
//   walletAddress: string,
//   web3: Web3,
//   slippageTolerance = new Percent(50, 10_000) // 50 bips, or 0.50%
// ): Promise<any> {
//   const options: SwapOptions = {
//     slippageTolerance: slippageTolerance,
//     deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from the current Unix time
//     recipient: walletAddress,
//   };

//   const methodParameters = SwapRouter.swapCallParameters([trade], options);

//   const tx: any = {
//     data: methodParameters.calldata,
//     to: SWAP_ROUTER_V2,
//     value: methodParameters.value,
//     from: walletAddress,
//     // maxFeePerGas: MAX_FEE_PER_GAS,
//     // maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS,
//   };

//   const gasEstimate = await web3.eth.estimateGas(tx);

//   tx["gas"] = gasEstimate;

//   const res = await web3.eth.sendTransaction(tx);

//   return res;
// }

// // //   // Helper Quoting and Pool Functions

// async function getOutputQuote(
//   route: Route<Currency, Currency>,
//   tokenIn: any,
//   tokenOut: any,
//   amountIn: any,
//   web3: Web3
// ) {
//   const { calldata } = SwapQuoter.quoteCallParameters(
//     route,
//     CurrencyAmount.fromRawAmount(
//       tokenIn,
//       fromReadableAmount(Number(amountIn), tokenIn.decimals).toString()
//     ),
//     TradeType.EXACT_INPUT,
//     {
//       useQuoterV2: true,
//     }
//   );

//   const quoteCallReturnData = await web3.eth.call({
//     to: QUOTER_CONTRACT_ADDRESS,
//     data: calldata,
//   });

//   return ethers.utils.defaultAbiCoder.decode(["uint256"], quoteCallReturnData);
// }

export async function setSwapRouterApproval(
  token: Token,
  web3: Web3,
  amountToApprove: any,
  walletAddress: any
): Promise<any> {
  try {
    const tokenContract = new web3.eth.Contract(erc20ABI, token.address);

    return await tokenContract.methods
      .approve(
        SWAP_ROUTER_V2,
        fromReadableAmount(amountToApprove, token.decimals).toString()
      )
      .send({ from: walletAddress });
  } catch (e) {
    console.error(e);
    return false;
  }
}

// Function to execute a swap route
export async function executeRoute(
  provider: Web3,
  tokenAddress: any,
  inputAmount: any,
  route: any,
  address: `0x${string}` | undefined
) {
  // Check if provider and signer are available
  if (!provider) {
    throw new Error("Cannot execute a trade without a connected wallet");
  }

  // Check if route is available for execution
  if (!route || !route.methodParameters) {
    throw new Error("Cannot execute as no route for execution");
  }

  // const gasPrice = BigNumber.from(parseInt(route.gasPriceWei.hex));
  // const gasLimit = BigNumber.from(parseInt(route.estimatedGasUsed.hex));

  // Construct transaction parameters
  const transactionParam: any = {
    data: route.methodParameters.calldata,
    to: SWAP_ROUTER_V2,
    value: route.methodParameters.value,
    from: address,
    // gasPirce: gasPrice.toHexString(),
  };

  // Execute transaction
  const res = await provider.eth.sendTransaction(transactionParam);

  return res;
}
