import React, { createContext, useState, ReactNode, useContext } from "react";
import { Alert } from "antd";

interface ErrorData {
  message: string;
  description?: string;
  type?: "error" | "warning" | "info" | "success";
}

interface ErrorContextProps {
  error: ErrorData | null;
  setError: (error: ErrorData | null) => void;
}

const ErrorContext = createContext<ErrorContextProps>({
  error: null,
  setError: () => {},
});

export const useError = () => useContext(ErrorContext);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [error, setError] = useState<ErrorData | null>(null);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
      {error && (
        <div className="centered-alert">
          <Alert
            message={error.message}
            description={error.description}
            type={error?.type || "error"}
            showIcon
            closable
            onClose={() => setError(null)}
          />
        </div>
      )}
    </ErrorContext.Provider>
  );
};
