// @ts-nocheck

import React, { useState, useEffect } from "react";
import {
  Select,
  Row,
  Col,
  Button,
  message,
  Layout,
  InputNumber,
  ConfigProvider,
} from "antd";
import { getTokenImage } from "../api/ApiCalls";
import { useAccount } from "wagmi";
import Web3 from "web3";
import {
  getTokenBalance,
  unwrapWShido,
  wrapWShido,
} from "../utils/poolFunctions";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import ArrowDown from "../assets/Svg/FiArrowDown.svg";
import { WSHIDO_ADDRESS } from "../constants";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useError } from "../contexts/ErrorContext";

const Wrap = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [fromCurrency, setFromCurrency] = useState(null);
  const [toCurrency, setToCurrency] = useState(null);
  const [fromAmount, setFromAmount] = useState(1);
  const [toAmount, setToAmount] = useState(1);
  const [swapTokens, setSwapTokens] = useState([]);
  const [swapOptions, setSwapOptions] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenInBalance, setTokenInBalance] = useState<string>();
  const [tokenOutBalance, setTokenOutBalance] = useState<string>();
  const { open } = useWeb3Modal();

  const [token0Image, setToken0Image] = useState<string>(
    "https://placehold.co/30x30/3B87F7/white?text=Coin"
  );
  const [token1Image, setToken1Image] = useState<string>(
    "https://placehold.co/30x30/3B87F7/white?text=Coin"
  );
  const { connector, address } = useAccount();

  const [loadingMessage, setLoadingMessage] = useState("");
  const { setError } = useError();

  useEffect(() => {
    const loadData = async () => {
      const tokens = [
        {
          id: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
          symbol: "SHIDO",
          name: "SHIDO",
          decimals: 6,
        },
        {
          id: "0x2c9508639e6B901e2Bb1e51115b11A93eDc1CdD9",
          symbol: "WSHIDO",
          name: "Wrapped SHID",
          decimals: 18,
        },
      ];

      setSwapTokens(tokens);

      const options = tokens.map((item) => ({
        value: item.symbol,
        label: item.symbol,
      }));

      setSwapOptions(options);

      if (options.length >= 2) {
        setFromCurrency(options[0].value);
        setToCurrency(options[1].value);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        refreshBalances();
      } catch (error) {
        console.error("Error refreshing balances:", error);
      }
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);

  const getTokenImages = async () => {
    if (fromCurrency) {
      const icon = await getTokenImage(WSHIDO_ADDRESS);
      setToken0Image(icon);
    }
    if (toCurrency) {
      const icon = await getTokenImage(WSHIDO_ADDRESS);
      setToken1Image(icon);
    }
  };

  useEffect(() => {
    Promise.all([refreshBalances(), getTokenImages()]);
  }, [fromCurrency, toCurrency]);

  const refreshBalances = async () => {
    const provider = new Web3(await connector?.getProvider());

    if (!address || !provider) {
      return;
    }

    if (fromCurrency) {
      const fromToken = swapTokens.find((item) => item.symbol === fromCurrency);
      const balance = await getTokenBalance(address, fromToken.id);
      setTokenInBalance(balance);
    }
    if (toCurrency) {
      const toToken = swapTokens.find((item) => item.symbol === toCurrency);
      const balance = await getTokenBalance(address, toToken.id);
      setTokenOutBalance(balance);
    }
  };

  const handleFromCurrencyChange = (value) => {
    if (value !== toCurrency) setFromCurrency(value);
  };

  const handleToCurrencyChange = (value) => {
    if (value !== fromCurrency) setToCurrency(value);
  };

  const handleFromAmountChange = (value: number) => {
    if (isNaN(value)) {
      console.error("Value is not a valid number.");
      return;
    }
    const amount = value;
    setFromAmount(amount);
    setToAmount(value);
  };

  const handleToAmountChange = (value) => {
    if (isNaN(value)) {
      console.error("Value is not a valid number.");
      return;
    }

    const amount = value;
    setToAmount(amount);
    setFromAmount(amount);
  };

  const isSwapDisabled = !fromCurrency || !toCurrency || !fromAmount;

  const switchTokens = () => {
    if (toCurrency && fromCurrency) {
      const newFromCurrency = toCurrency;
      const newToCurrency = fromCurrency;

      setFromCurrency(newFromCurrency);
      setToCurrency(newToCurrency);

      setToAmount(fromAmount);
      setFromAmount(toAmount);

      return;
    } else if (fromCurrency) {
      const newToCurrency = fromCurrency;

      setToCurrency(newToCurrency);
      return;
    } else if (toCurrency) {
      const newFromCurrency = toCurrency;

      setToCurrency(newFromCurrency);
      setToAmount(fromAmount);
      setFromAmount(toAmount);
      return;
    }
  };

  const onWrap = async () => {
    setLoading(true);
    try {
      let response = null;
      if (fromCurrency === "WSHIDO") {
        response = await unwrapWShido(address, String(fromAmount));
      } else {
        response = await wrapWShido(address, String(fromAmount));
      }

      if (response.hash) {
        await refreshBalances();
        setError({
          message: "Success",
          description: `Successfully ${
            fromCurrency === "WSHIDO" ? "unwrapped" : "wrapped"
          } ${fromAmount} ${fromCurrency} to ${toCurrency}`,
          type: "success",
        });
      } else {
        setError({
          message: "Something went wrong",
          type: "error",
        });
      }
      setLoading(false);
    } catch (e: any) {
      setError({
        message: `Error ${
          fromCurrency === "WSHIDO" ? "unwrapping" : "wrapping"
        } token.`,
        description: e.message,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Layout
        style={{
          marginTop: "20px",
          backgroundColor: "#252527",
          borderRadius: "12px",
          padding: sm ? "36px" : "12px",
          alignItems: "center",
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              InputNumber: {
                colorBgContainer: "transparent",
                colorBorder: "transparent",
                inputFontSizeSM: 18,
                fontSize: 18,
              },
              Select: {
                fontSizeSM: 18,
                fontSize: 18,
                optionFontSize: 14,
                colorPrimary: "transparent",
                colorPrimaryHover: "transparent",
                controlOutline: "transparent",
              },
            },
          }}
        >
          <Row style={{ justifyContent: "center", marginBottom: "24px" }}>
            <Text size="lg">Wrap/Unwrap</Text>
          </Row>
          <Row
            style={{
              padding: xs ? "14px" : "24px",
              width: xxl ? "720px" : md ? "580px" : xs ? "347px" : "488px",
              backgroundColor: "#37373C",
              borderRadius: "20px",
            }}
          >
            {/* /////////////////////////////////////////////////// */}
            <Row style={{ width: "100%" }}>
              <Row
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Text size="sm">
                  Balance:{" "}
                  {tokenInBalance ? Number(tokenInBalance).toFixed(6) : "0"}
                </Text>
                <Button
                  size="small"
                  type="primary"
                  onClick={() =>
                    tokenInBalance &&
                    handleFromAmountChange(Number(tokenInBalance))
                  }
                >
                  Max
                </Button>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  borderRadius: "12px",
                  backgroundColor: "#252527",
                  padding: "18px 16px",
                  marginTop: "12px",
                }}
              >
                <Col style={{ display: "flex", gap: "12px" }}>
                  <img
                    src={token0Image}
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                    }}
                  >
                    <Text
                      size="xs"
                      style={{ color: "#7C7C82", fontWeight: "400" }}
                    >
                      Swap from
                    </Text>
                    <Text size="md" style={{ fontWeight: "600" }}>
                      {fromCurrency}
                    </Text>
                    {/* <Select
                      style={{ width: "100px" }}
                      size="small"
                      onChange={handleFromCurrencyChange}
                      options={swapOptions}
                      className="swap-select"
                      value={fromCurrency}
                    ></Select> */}
                  </Col>
                </Col>
                <Col>
                  <InputNumber
                    type="number"
                    size="small"
                    value={fromAmount}
                    onChange={handleFromAmountChange}
                    variant="borderless"
                    controls={false}
                    className="SwapInputNumber"
                    style={{ width: sm ? "150px" : "90px" }}
                  ></InputNumber>
                </Col>
              </Row>
              <Row
                style={{
                  position: "relative",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px",
                    borderRadius: "50%",
                    backgroundColor: "#3B87F7",
                    position: "absolute",
                    top: "-14px",
                    cursor: "pointer",
                  }}
                >
                  <img src={ArrowDown} onClick={switchTokens} />
                </div>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  borderRadius: "12px",
                  backgroundColor: "#252527",
                  padding: "18px 16px",
                  marginTop: "24px",
                }}
              >
                <Col style={{ display: "flex", gap: "12px" }}>
                  <img
                    src={token1Image}
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                    }}
                  >
                    <Text
                      size="xs"
                      style={{ color: "#7C7C82", fontWeight: "400" }}
                    >
                      Swap To
                    </Text>
                    <Text size="md" style={{ fontWeight: "600" }}>
                      {toCurrency}
                    </Text>
                    {/* <Select
                      style={{ width: "100px" }}
                      size="small"
                      onChange={handleToCurrencyChange}
                      options={swapOptions}
                      className="swap-select"
                      value={toCurrency}
                    ></Select> */}
                  </Col>
                </Col>
                <Col>
                  <InputNumber
                    type="number"
                    size="small"
                    value={toAmount}
                    onChange={handleToAmountChange}
                    variant="borderless"
                    className="SwapInputNumber"
                    controls={false}
                    style={{ width: sm ? "150px" : "90px" }}
                  ></InputNumber>
                </Col>
              </Row>
              <Row
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                  marginTop: "12px",
                }}
              >
                <Text size="sm">
                  Balance:{" "}
                  {tokenOutBalance ? Number(tokenOutBalance).toFixed(6) : 0}
                </Text>
                <Button
                  size="small"
                  type="primary"
                  onClick={() =>
                    tokenOutBalance &&
                    handleToAmountChange(Number(tokenOutBalance))
                  }
                >
                  Max
                </Button>
              </Row>
            </Row>

            {address ? (
              <>
                {fromCurrency && (
                  <Button
                    type="primary"
                    style={{ marginTop: "24px", width: "100%" }}
                    disabled={isSwapDisabled}
                    onClick={() => onWrap()}
                    loading={loading}
                  >
                    {" "}
                    {loading
                      ? loadingMessage
                      : fromCurrency === "WSHIDO"
                      ? "Unwrap"
                      : "Wrap"}
                  </Button>
                )}
              </>
            ) : (
              <Button
                type="primary"
                style={{ marginTop: "24px", width: "100%" }}
                onClick={() => {
                  open({ view: "Networks" });
                }}
              >
                Connect Wallet
              </Button>
            )}
            <Row
              style={{
                width: "100%",
              }}
            ></Row>
          </Row>
        </ConfigProvider>
      </Layout>
    </>
  );
};

export default Wrap;
