import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PoolType } from "../../utils";

type poolsState = {
  pools: PoolType[];
};

const initialState: PoolType[] = [];

export const poolsSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    setPools: (state, action: PayloadAction<PoolType[]>) => {
      state.length = 0;
      state.push(...action.payload);
    },
  },
});

export const { setPools } = poolsSlice.actions;

export const poolsState = (state: RootState) => state.pools;

export default poolsSlice.reducer;
