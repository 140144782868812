import Layout from "antd/es/layout/layout";
import React from "react";
import Hero from "../components/HomeComponents/Hero";
import PlatformOverview from "../components/HomeComponents/PlatformOverview";
import { Row } from "antd";
import SwapOverview from "../components/HomeComponents/SwapOverview";
import FeaturedPools from "../components/HomeComponents/FeaturedPools";

const Home = () => {
  return (
    <Layout style={{ marginTop: "20px", backgroundColor: "transparent" }}>
      <Row style={{ flexDirection: "column", gap: "20px" }}>
        <Hero />
        <PlatformOverview />
        <SwapOverview />
        <FeaturedPools />
      </Row>
    </Layout>
  );
};

export default Home;
