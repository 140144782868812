import { Button, Col, Row, Spin } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { useEffect } from "react";
import Text from "../../components/Text";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { poolsState, setPools } from "../../redux/reducers";
import PoolCards from "../PoolCards";
import { useQuery } from "@apollo/client";
import { LOAD_FEATURED_POOLS } from "../../graphql";

const FeaturedPools = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const navigate = useNavigate();
  const pools = useSelector(poolsState);
  const dispatch = useDispatch();
  const handleViewPoolButton = () => {
    navigate("/pools");
  };

  const { error, loading, data } = useQuery(LOAD_FEATURED_POOLS);

  useEffect(() => {
    if (data) {
      dispatch(setPools(data.pools));
    }
  }, [data]);

  return (
    <Row
      style={{
        width: "100%",
        padding: md ? "36px" : sm ? "24px" : "16px",
        borderRadius: "12px",
        backgroundColor: "#252527",
      }}
    >
      <Row
        style={{
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Text size={"lg"} style={{ fontSize: md ? "32px" : "28px" }}>
          Featured Pools
        </Text>
        {sm && (
          <Button type="primary" onClick={handleViewPoolButton}>
            View all pools
          </Button>
        )}
      </Row>

      {pools.length > 0 ? (
        md ? (
          <Row
            gutter={[16, 16]}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            {pools.slice(0, 3).map((pool, index) => (
              <Col key={index} span={lg ? 8 : md ? 12 : 24}>
                <PoolCards Pool={pool} index={index} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row style={{ width: "100%", marginBottom: "20px", gap: "16px" }}>
            {pools.slice(0, 3).map((pool, index) => (
              <Col key={index} span={24}>
                <PoolCards Pool={pool} index={index} />
              </Col>
            ))}
          </Row>
        )
      ) : (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Spin size="large" />
        </Col>
      )}

      {xs && (
        <Button
          type="primary"
          onClick={handleViewPoolButton}
          style={{ width: "100%" }}
        >
          View all pools
        </Button>
      )}
    </Row>
  );
};

export default FeaturedPools;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
