import axios from "../axios";
import { PoolType } from "../../utils/types";

interface PoolData {
  transaction_hash: string;
}

export const GetAllPool = async () => {
  try {
    const endPoint = "/pools/";
    const res = await axios.get<PoolType[]>(endPoint);
    if (res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("error Get Pool Api ", err);
    return Promise.reject(err);
  }
};

export const getTokens = async (search?: string) => {
  try {
    const endPoint = `https://testnet-explorer-api.shidoscan.com/shido/tokens?limit=20${
      search ? "&value=" + search : ""
    }`;
    const res = await axios.get<any>(endPoint);
    if (res?.data) {
      return res.data.data.tokens;
    }
  } catch (err) {
    console.log("error getTokens  Api ", err);
    return Promise.reject(err);
  }
};

export const searchTokens = async (search?: string) => {
  try {
    const endPoint = `https://testnet-explorer-api.shidoscan.com/shido/tokens?limit=20${
      search ? "&value=" + search : ""
    }`;
    const res = await axios.get<any>(endPoint);
    if (res?.data) {
      return res.data.data;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTokenImage = async (address: string) => {
  try {
    const endPoint = "tokens/image/" + address;
    const res = await axios.get<any>(endPoint);
    if (res?.data) {
      return res.data.url;
    }
  } catch (err) {
    return "";
  }
};

export const getRoute = async (
  fromToken: string,
  toToken: string,
  fromAmount: any,
  recipient: `0x${string}` | undefined,
  swapType = "exactIn",
  slippage = 0.5
) => {
  try {
    const endPoint =
      "https://api.shido-route-generator.baboons.tech/get-route/";

    const params = {
      fromToken,
      toToken,
      fromAmount,
      recipient,
      swapType,
      slippage,
    };

    const response = await axios.get(endPoint, { params });

    if (response?.data) {
      return response.data;
    } else {
      console.log(response);
      throw new Error("Failed to fetch  getRoute");
    }
  } catch (err) {
    console.error("Error fetching getRoute :", err);
    throw err;
  }
};

export const fetchTokenPrices = async (tokenAddresses: any) => {
  try {
    const queryParams = new URLSearchParams();
    tokenAddresses.forEach((address: any) =>
      queryParams.append("addresses", address)
    );

    const response = await axios.get(
      `/tokens/prices/?${queryParams.toString()}`
    );

    return response.data;
  } catch (err) {
    console.error("Error fetching token prices:", err);
    throw err;
  }
};

export const fetchTokenImages = async (tokenAddresses: any) => {
  try {
    const queryParams = new URLSearchParams();
    tokenAddresses.forEach((address: any) =>
      queryParams.append("addresses", address)
    );

    const response = await axios.get(
      `/tokens/images/?${queryParams.toString()}`
    );

    return response.data;
  } catch (err) {
    console.error("Error fetching token prices:", err);
    throw err;
  }
};
