import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { LOAD_POOL_HOUR_DATA } from "../graphql";
import { useQuery } from "@apollo/client";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

function formatDate(timestamp: number): string {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formattedDate = `${hours}:${minutes}`;

  return formattedDate;
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "Time",
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "Price",
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

const LiquidityChart = ({ pool }: any) => {
  const [data, setData] = useState([]);
  const [labels, setLables] = useState([]);

  const hourDataQuery = useQuery(LOAD_POOL_HOUR_DATA, {
    variables: { id: pool.id },
  });

  useEffect(() => {
    if (!hourDataQuery.loading) {
      const backendLabels = hourDataQuery.data.poolHourDatas.map((entry: any) =>
        formatDate(Number(entry.periodStartUnix) * 1000)
      );
      setLables(backendLabels);
      setData(hourDataQuery.data.poolHourDatas);
    }
  }, [hourDataQuery]);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Liquidity",
        data: data.map((entry: any) => entry.liquidity),
        borderColor: "rgb(59, 135, 247)",
        backgroundColor: "red",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LiquidityChart;
