import axios from "../axios";

interface GetChallengeRes {
  data: string;
  status: string;
}

export const GetChallenge = async (wallet_address: string) => {
  try {
    const endPoint = `/user/challenge/?wallet_address=${wallet_address}`;
    const res = await axios.get<GetChallengeRes>(endPoint);
    return res.data;
  } catch (err) {
    console.log("error while get signature", err);
    return Promise.reject(err);
  }
};

interface WalletLogin {
  access_token: string;
  refresh_token: string;
  user_id: number;
  wallet_address: string;
}
interface GetWalletLogin {
  data: WalletLogin;
  status: string;
}

export const WalletLogin = async (payload: any) => {
  try {
    const endPoint = "/user/login/";
    const res = await axios.post<GetWalletLogin>(endPoint, payload);
    if (!res?.data) throw "Something went wrong";
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
