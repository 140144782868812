// @ts-nocheck

import { ConfigProvider } from "antd";
import App from "./App";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { arbitrum, mainnet, sepolia } from "viem/chains";
import { breakPoints } from "./utils/constants";
import { type Chain } from "viem";
import { defineChain } from "viem";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "ac6818d713537310b1b5435748cba3f4";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

export const shido = defineChain({
  id: 9007,
  name: "Shido Testnet",
  network: "shido",
  nativeCurrency: {
    decimals: 18,
    name: "Shido",
    symbol: "Shido",
  },
  rpcUrls: {
    default: { http: ["https://rpc-testnet-nodes.shidoscan.com"] },
    public: { http: ["https://rpc-testnet-nodes.shidoscan.com"] },
  },
  blockExplorers: {
    etherscan: { name: "ShidoScan", url: "https://shidoscan.com" },
    default: { name: "ShidoScan", url: "https://shidoscan.com" },
  },
  contracts: {
    // multicall3: {
    //   address: "0xca11bde05977b3631167028862be2a173976ca11",
    //   blockCreated: 15921452,
    // },
  },
});

const chains = [shido];

const wagmiConfig: any = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  // enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

const ThemedApp = () => {
  const { screenXS, screenSM, screenMD, screenLG, screenXL, screenXXL } =
    breakPoints;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <ConfigProvider
      theme={{
        token: {
          screenXS,
          screenXSMin: screenXS,
          screenXSMax: screenSM - 1,
          screenSM,
          screenSMMin: screenSM,
          screenSMMax: screenMD - 1,
          screenMD,
          screenMDMin: screenMD,
          screenMDMax: screenLG - 1,
          screenLG,
          screenLGMin: screenLG,
          screenLGMax: screenXL - 1,
          screenXL,
          screenXLMin: screenXL,
          screenXLMax: screenXXL - 1,
          screenXXL,
          screenXXLMin: screenXXL,
        },
        components: {
          Button: {
            contentFontSize: sm ? 16 : 14,
            fontWeight: 600,
            controlHeight: 43,
            paddingInline: sm ? 26 : 16,
            colorTextDisabled: "white",
            colorPrimary: "#3B87F7",
            colorPrimaryHover: "#2E6BCC",
          },
          Modal: {
            contentBg: "#37373C",
            colorIcon: "white",
            colorIconHover: "white",
            borderRadiusLG: 12,
          },
          Menu: {
            itemBg: "transparent",
            itemColor: "white",
            itemHoverColor: "#B6B7BF",
            popupBg: "#252527",
          },
          Dropdown: {
            colorBgElevated: "#252527",
            colorText: "white",
            controlItemBgHover: "#7C7C82",
            colorTextDisabled: "white",
          },
          InputNumber: {
            colorBgContainer: "#37373C",
            colorBorder: "#37373C",
            colorText: "white",
            paddingInline: 18,
            handleHoverColor: "white",
            handleBg: "#84b1fa",
          },
          Table: {
            colorBgContainer: "#37373C",
            cellFontSize: 14,
            colorText: "white",
            fontWeightStrong: 500,
            headerSplitColor: "transparent",
            borderColor: "rgba(124, 124, 130, 0.25)",
            cellPaddingBlock: 14,
            headerColor: "#7C7C82",
          },
          Pagination: {
            itemActiveBg: "transparent",
            colorText: "white",
            itemBg: "red",
            colorPrimary: "#3B87F7",
          },
          Select: {
            colorBgContainer: "#252527",
            colorBorder: "#252527",
            colorText: "white",
            colorBgElevated: "#252527",
            optionSelectedBg: "#3B87F7",
            controlHeight: 41,
            optionActiveBg: "#37373C",
          },
          Slider: {
            handleColor: "#3B87F7",
            trackBg: "#3B87F7",
            trackHoverBg: "#3B87F7",
            dotActiveBorderColor: "#3B87F7",
            dotBorderColor: "#3B87F7",
            handleActiveColor: "#3B87F7",
            colorPrimaryBorderHover: "#3B87F7",
            handleSize: 12,
            railBg: "#494949",
            railHoverBg: "#494949",
            railSize: 8,
            borderRadiusXS: 5,
          },
          Drawer: {
            colorBgElevated: "#252527",
          },
        },
      }}
    >
      <WagmiConfig config={wagmiConfig}>
        <App />
      </WagmiConfig>
    </ConfigProvider>
  );
};

export default ThemedApp;
