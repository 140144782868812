import { Button, Col, Radio, Row, Select, ConfigProvider } from "antd";
import Layout from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PoolType } from "../utils/types";
import { formatDistanceToNow } from "date-fns";
import { Line, LineConfig } from "@ant-design/plots";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import type { TableColumnsType } from "antd";
import { Table } from "antd";
import { getTokenImage } from "../api/ApiCalls";
import { getPoolData, getPrice } from "../utils/poolFunctions";
import UniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";
import Web3 from "web3";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import AddLiquidityModal from "../components/AddLiquidityModal";
import { PoolDataType, formatNumberUniversal } from "../utils";
import BigNumber from "bignumber.js";
import { CHAIN_ID } from "../constants";
import { LOAD_POOL, LOAD_POOL_TRANSACTIONS } from "../graphql";
import { useQuery } from "@apollo/client";
import LiquidityChart from "../components/LiquidityChart";
import PriceChart from "../components/PriceChart";
import VolumeChart from "../components/VolumeChart";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useError } from "../contexts/ErrorContext";

const { Option } = Select;

const radioGroupStyle = {
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
};

const radioButtonStyle = {
  backgroundColor: "#f0f0f0",
  borderRadius: "4px",
  margin: "0 3px",
  // padding: "8px 16px",
};

const radioButtonCheckedStyle = {
  ...radioButtonStyle,
  backgroundColor: "#1890ff",
  color: "white",
};

const SinglePool = () => {
  const [pool, setPool] = useState<PoolType>({
    id: "",
    token0: {
      id: "",
      address: "",
      symbol: "",
      name: "",
      decimals: 18,
      totalSupply: "",
      volume: "",
      volumeUSD: "",
      untrackedVolumeUSD: "",
      feesUSD: "",
      txCount: 0,
      poolCount: 1,
      totalValueLocked: "",
      totalValueLockedUSD: "",
      totalValueLockedUSDUntracked: "",
      derivedSHIDO: "",
    },
    token1: {
      id: "",
      address: "",
      symbol: "",
      name: "",
      decimals: 18,
      totalSupply: "",
      volume: "",
      volumeUSD: "",
      untrackedVolumeUSD: "",
      feesUSD: "",
      txCount: 0,
      poolCount: 1,
      totalValueLocked: "",
      totalValueLockedUSD: "",
      totalValueLockedUSDUntracked: "",
      derivedSHIDO: "",
    },
    contract_address: "",
    createdAtTimestamp: 0,
    createdAtBlockNumber: 0,
    feeTier: 0,
    liquidity: 0,
    sqrtPrice: "",
    token0Price: "",
    token1Price: "",
    tick: 0,
    observationIndex: 0,
    volumeToken0: "",
    volumeToken1: "",
    volumeUSD: "",
    untrackedVolumeUSD: "",
    feesUSD: "",
    txCount: 0,
    collectedFeesToken0: "",
    collectedFeesToken1: "",
    collectedFeesUSD: "",
    totalValueLockedToken0: "",
    totalValueLockedToken1: "",
    totalValueLockedSHIDO: "",
    totalValueLockedUSD: "",
    totalValueLockedUSDUntracked: "",
    liquidityProviderCount: 0,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { open } = useWeb3Modal();
  const [coin1, setCoin1] = useState<number | null>(null);
  const [coin2, setCoin2] = useState<number | null>(null);
  const [tokenPrices, setTokenPrices] = useState({ token0: 0, token1: 0 });
  const { connector, address } = useAccount();
  const { chain } = useNetwork();
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();
  const [priceType, setPriceType] = useState(pool.token0.symbol);
  const { setError } = useError();
  const ensureCorrectNetwork = async () => {
    if (!pendingChainId && !isLoading && chain?.id !== CHAIN_ID) {
      switchNetwork?.(CHAIN_ID);
    }
  };

  useEffect(() => {
    ensureCorrectNetwork();
  }, [chain, switchNetwork, pendingChainId, isLoading]);

  const [token0Image, setToken0Image] = useState<string>(
    "https://placehold.co/30x30/3B87F7/white?text=Coin"
  );
  const [token1Image, setToken1Image] = useState<string>(
    "https://placehold.co/30x30/3B87F7/white?text=Coin"
  );

  const [poolData, setPoolData] = useState<PoolDataType>({
    fee: BigNumber(0),
    liquidity: "0",
    sqrtPriceX96: "0",
    tick: "0",
    tickSpacing: "0",
    token0: "",
    token1: "",
  });

  const [poolTransactions, setPoolTransactions] = useState<any>({
    swaps: [],
    burns: [],
    mints: [],
  });

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  const [selectedChart, setSelectedChart] = useState("volume");

  // Handler to update state
  const handleChartChange = (value: string) => {
    setSelectedChart(value);
  };

  const params = useParams();

  const { error, loading, data } = useQuery(LOAD_POOL, {
    variables: { id: params?.poolAddress },
  });

  const transactionsQuery = useQuery(LOAD_POOL_TRANSACTIONS, {
    variables: { id: params?.poolAddress },
  });

  useEffect(() => {
    if (!transactionsQuery.loading) {
      setPoolTransactions(transactionsQuery.data);
    }
  }, [transactionsQuery]);

  const onChange = (value: any, arg2: number) => {
    if (arg2 === 1) {
      setCoin1(value);
    } else {
      setCoin2(value);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getTokenImages = async () => {
    const [token0, token1] = await Promise.all([
      getTokenImage(pool.token0.id),
      getTokenImage(pool.token1.id),
    ]);
    setToken0Image(token0);
    setToken1Image(token1);
  };

  useEffect(() => {
    if (pool && pool.token0.id && pool.token1.id) {
      getTokenImages();
    }
    setPriceType(pool.token0.symbol);
  }, [pool]);

  const calculateExchangeRate = async () => {
    try {
      setPool(data.pool);

      if (connector?.getProvider()) {
        const web3 = new Web3(await connector?.getProvider());

        const poolContract = new web3.eth.Contract(
          UniswapV3Pool.abi,
          data.pool.id
        );
        const poolData = await getPoolData(poolContract);

        setPoolData(poolData);
      }

      const prices = await getPrice({
        sqrtPriceX96: data.pool.sqrtPrice,
        Decimal0: data.pool.token1.decimals,
        Decimal1: data.pool.token0.decimals,
      });

      setTokenPrices(prices);
    } catch (error: any) {
      setError({
        message: "Error  calculating exchange rate",
        description: error.message,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (data && data.pool) {
      calculateExchangeRate();
    }
  }, [data, connector]);

  const handlePriceTypeChange = (e: any) => {
    setPriceType(e.target.value);
  };

  const navigate = useNavigate();

  const handleViewPoolButton = () => {
    navigate("/swap");
  };

  const connectwallet = () => {
    open({ view: "Networks" });
  };

  return (
    <>
      <Layout
        style={{
          marginTop: "20px",
          backgroundColor: "#252527",
          borderRadius: "12px",
          padding: sm ? "36px" : "16px",
        }}
      >
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Col
            style={{ display: "flex", alignItems: "center", gap: "24px" }}
            span={sm ? "" : 24}
          >
            <div>
              <img
                src={token0Image}
                style={{
                  position: "relative",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
                className={`${sm ? "" : "coin-icon-size"}`}
                width={40}
                height={40}
              />

              <img
                src={token1Image}
                width={40}
                height={40}
                style={{
                  marginLeft: sm ? "-18px" : "-8px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
                className={`${sm ? "" : "coin-icon-size"}`}
              />
            </div>
            <div>
              {pool && (
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    alignItems: "baseline",
                  }}
                >
                  <h4
                    style={{
                      color: "white",
                      fontSize: "24px",
                      fontWeight: "700",
                      margin: 0,
                    }}
                  >
                    {pool.token0.symbol
                      ? `${pool.token0.symbol} / ${pool.token1.symbol}`
                      : "Coin / Coin"}
                  </h4>
                  <p
                    style={{
                      margin: 0,
                      color: "#3B87F7",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Swap Fee : {Number(pool.feeTier) / 10000}%
                  </p>
                </div>
              )}
              {pool && (
                <h6
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#9B9CA3",
                    margin: 0,
                  }}
                >
                  {sm ? pool.id : shortenString(pool.id)}
                </h6>
              )}
            </div>
          </Col>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  defaultBg: "#37373C",
                  colorBorder: "transparent",
                  colorText: "white",
                  defaultHoverBg: "#46464d",
                  defaultHoverBorderColor: "#46464d",
                  defaultHoverColor: "white",
                },
              },
            }}
          >
            <Col span={sm ? "" : 24} style={{ display: "flex", gap: "20px" }}>
              <Button
                type="default"
                onClick={handleViewPoolButton}
                style={{ width: sm ? "" : "100%" }}
              >
                Swap
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  address ? showModal() : connectwallet();
                }}
                style={{ width: sm ? "" : "100%" }}
                disabled={!pool}
              >
                Add Liquidity
              </Button>
            </Col>
          </ConfigProvider>
        </Row>

        <Row
          style={{
            marginTop: "24px",
            gap: "12px",
            flexDirection: sm ? "row" : "column",
          }}
        >
          <Col
            style={{
              display: "flex",
              borderRadius: "25px",
              backgroundColor: "#37373C",
              padding: " 8px 12px 8px 8px",
              gap: "10px",
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <img
              src={token0Image}
              style={{
                height: "24px",
                width: "24px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
            <h4
              style={{
                fontWeight: "500",
                fontSize: "16px",
                color: "white",
                margin: 0,
              }}
            >
              1 {`${pool?.token0.symbol}`} ={" "}
              {formatNumberUniversal(String(tokenPrices.token0))}{" "}
              {`${pool?.token1.symbol} `}
            </h4>
          </Col>
          <Col
            style={{
              display: "flex",
              borderRadius: "25px",
              backgroundColor: "#37373C",
              padding: " 8px 12px 8px 8px",
              gap: "10px",
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <img
              src={token1Image}
              style={{
                height: "24px",
                width: "24px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
            <h4
              style={{
                fontWeight: "500",
                fontSize: "16px",
                color: "white",
                margin: 0,
              }}
            >
              1 {`${pool?.token1?.symbol}`} ={" "}
              {formatNumberUniversal(String(tokenPrices.token1))}{" "}
              {`${pool?.token0?.symbol} `}
            </h4>
          </Col>
        </Row>

        <Row
          style={{
            gap: "16px",
            marginTop: "24px",
            flexWrap: sm ? "nowrap" : "wrap",
          }}
        >
          <Col
            style={{
              width: sm ? "300px" : "100%",
              //   height: "fit-content",
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#37373C",
              borderRadius: "12PX",
              padding: "16px 16px 24px 16px",
            }}
          >
            <Row
              style={{
                flexDirection: "column",
                backgroundColor: "rgba(255, 255, 255, 0.07)",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <h4
                style={{
                  color: "#9B9CA3",
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: 0,
                  marginBottom: "12px",
                }}
              >
                Total Tokens Locked
              </h4>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={token0Image}
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <h5
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "white",
                      margin: 0,
                    }}
                  >
                    {pool?.token0?.symbol}
                  </h5>
                </div>

                <h5
                  style={{
                    color: "white",
                    margin: 0,
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {formatNumberUniversal(
                    String(pool.totalValueLockedToken0 || "0")
                  )}
                </h5>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={token1Image}
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <h5
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "white",
                      margin: 0,
                    }}
                  >
                    {pool?.token1?.symbol}
                  </h5>
                </div>

                <h5
                  style={{
                    color: "white",
                    margin: 0,
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {formatNumberUniversal(
                    String(pool.totalValueLockedToken1 || "0")
                  )}
                </h5>
              </Col>
            </Row>

            <Row
              style={{
                flexDirection: "column",
                marginTop: "36px",
                position: "relative",
              }}
            >
              <h4
                style={{
                  margin: 0,
                  color: "#9B9CA3",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                TVL
              </h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h1
                  style={{
                    margin: 0,
                    color: "white",
                    fontSize: "24px",
                    fontWeight: "600",
                  }}
                >
                  $
                  {formatNumberUniversal(
                    String(pool.totalValueLockedUSD || "0")
                  )}
                </h1>
                {/* <h6
                  style={{
                    margin: 0,
                    color: "#39CA7F",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  +0.75%
                </h6> */}
              </div>
            </Row>

            <Row
              style={{
                flexDirection: "column",
                marginTop: "36px",
                position: "relative",
              }}
            >
              <h4
                style={{
                  margin: 0,
                  color: "#9B9CA3",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                24H Fees
              </h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h1
                  style={{
                    margin: 0,
                    color: "white",
                    fontSize: "24px",
                    fontWeight: "600",
                  }}
                >
                  ${formatNumberUniversal(String(pool.feesUSD || "0"))}
                </h1>
                {/* <h6
                  style={{
                    margin: 0,
                    color: "#39CA7F",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  +0.25%
                </h6> */}
              </div>
            </Row>
          </Col>
          <Col
            style={{
              minWidth: "200px",
              maxWidth: "100%",
              flexGrow: 1,
              backgroundColor: "#37373C",
              borderRadius: "12px",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            {/* <Row
              style={{
                flexDirection: "column",
              }}
            > */}
            {/* <h4
                style={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "600",
                  margin: 0,
                }}
              >
                $232.03M
              </h4> */}
            {/* <h5
                style={{
                  color: "#9B9CA3",
                  fontSize: "12px",
                  fontWeight: "400",
                  margin: 0,
                }}
              >
                Feb 15 2024 (UTC)x
              </h5> */}
            {/* </Row> */}
            <Row
              style={{
                marginTop: "16px",
                flexGrow: 1,
              }}
            >
              <Row style={{ width: "100%" }}>
                {selectedChart === "liquidity" && (
                  <LiquidityChart pool={pool} />
                )}
                {selectedChart === "volume" && <VolumeChart pool={pool} />}
              </Row>
              <Row style={{ width: "100%" }}>
                {selectedChart === "price" && (
                  <>
                    <Row style={{ width: "100%" }}>
                      <Radio.Group
                        value={priceType}
                        onChange={handlePriceTypeChange}
                        style={radioGroupStyle}
                      >
                        <Radio.Button
                          value={pool.token0.symbol}
                          style={
                            priceType === pool.token0.symbol
                              ? radioButtonCheckedStyle
                              : radioButtonStyle
                          }
                        >
                          {pool.token0.symbol}
                        </Radio.Button>
                        <Radio.Button
                          value={pool.token1.symbol}
                          style={
                            priceType === pool.token1.symbol
                              ? radioButtonCheckedStyle
                              : radioButtonStyle
                          }
                        >
                          {pool.token1.symbol}
                        </Radio.Button>
                      </Radio.Group>
                    </Row>
                    <Row style={{ width: "100%" }}>
                      <PriceChart pool={pool} priceType={priceType} />
                    </Row>
                  </>
                )}
              </Row>
            </Row>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Select
                defaultValue="volume"
                style={{ width: 120 }}
                onChange={handleChartChange}
              >
                <Option value="liquidity">Liquidity</Option>
                <Option value="volume">Volume</Option>
                <Option value="price">Price</Option>
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <TranscationTable
            transactions={poolTransactions}
            token0Symbol={pool.token0.symbol}
            token1Symbol={pool.token1.symbol}
          />
        </Row>
      </Layout>

      {/* modal here */}
      {pool.id && (
        <AddLiquidityModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          onChange={onChange}
          coin1={coin1}
          coin2={coin2}
          pool={pool}
          token0Image={token0Image}
          token1Image={token1Image}
          poolData={poolData}
          exchangeRate={tokenPrices}
          getPoolFromApi={calculateExchangeRate}
          transactionsQuery={transactionsQuery}
        />
      )}
    </>
  );
};

export default SinglePool;

interface DataType {
  key: string;
  Type: string;
  Totalvalue: number;
  Tokenamount: number;
  Token: string;
  addres: string;
  time: string;
}

type Filters = {
  name?: string | null;
};

interface TranscationTableInterface {
  transactions: any;
  token0Symbol: string;
  token1Symbol: string;
}

const TranscationTable = ({
  transactions,
  token0Symbol,
  token1Symbol,
}: TranscationTableInterface) => {
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});

  const handleFilter = (value: string | null) => {
    setFilteredInfo({ name: value });
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text: string) => {
        const timestampMilliseconds = parseInt(text) * 1000;
        return (
          <span style={{ color: "#3B87F7", whiteSpace: "nowrap" }}>
            {formatDistanceToNow(new Date(timestampMilliseconds), {
              addSuffix: true,
            })}
          </span>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "USD",
      dataIndex: "amountUSD",
      key: "amountUSD",
      render: (text: string) => (
        <span>{formatNumberUniversal(String(text) || "0")}</span>
      ),
    },
    {
      title: token0Symbol,
      dataIndex: "amount0",
      key: "amount0",
      render: (text: string) => (
        <span>{formatNumberUniversal(String(Math.abs(Number(text))))}</span>
      ),
    },
    {
      title: token1Symbol,
      dataIndex: "amount1",
      key: "amount1",
      render: (text: string) => (
        <span>{formatNumberUniversal(String(Math.abs(Number(text))))}</span>
      ),
    },
    {
      title: "Wallet",
      dataIndex: "recipient",
      key: "recipient",
      render: (text: string, obj: any) => (
        <span>
          {text
            ? text
            : obj.type === "mint" || obj.type === "burn"
            ? obj.origin
            : obj.sender
            ? obj.sender
            : obj.owner}
        </span>
      ),
    },
  ];

  const swapTransactions = transactions.swaps
    ? transactions.swaps.map((transaction: any) => ({
        ...transaction,
        type: "swap",
      }))
    : [];

  const burnTransactions = transactions.burns
    ? transactions.burns.map((transaction: any) => ({
        ...transaction,
        type: "burn",
      }))
    : [];
  const mintTransactions = transactions.mints
    ? transactions.mints.map((transaction: any) => ({
        ...transaction,
        type: "mint",
      }))
    : [];

  const transactionsWithTypes = [
    ...swapTransactions,
    ...burnTransactions,
    ...mintTransactions,
  ];

  return (
    <>
      <Row
        style={{
          width: "100%",
          marginTop: "24px",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h3
          style={{
            margin: 0,
            fontSize: "20px",
            fontWeight: "600",
            color: "white",
          }}
        >
          Transactions
        </h3>

        <Button type="primary" size="small" onClick={() => handleFilter(null)}>
          All
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => handleFilter("swap")}
        >
          Swap
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => handleFilter("mint")}
        >
          Mint
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => handleFilter("burn")}
        >
          Burn
        </Button>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "8px",
          borderRadius: "8px",
          backgroundColor: "#37373C",
          padding: "16px",
          overflow: "auto",
        }}
      >
        <Table
          pagination={{ pageSize: 5 }}
          style={{ width: "100%" }}
          columns={columns}
          dataSource={transactionsWithTypes.filter(
            (item: any) =>
              !filteredInfo.name || item.type.includes(filteredInfo.name)
          )}
        />
      </Row>
    </>
  );
};

function shortenString(str: string) {
  if (str) {
    return str.slice(0, 6) + "..." + str.slice(-6);
  } else {
    return "";
  }
}
