import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  from,
  HttpLink,
  InMemoryCache,
  OperationVariables,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const SERVER_URL_GQL =
  "https://fgpuqrvcwqpk2wxb11327.cleavr.one/subgraphs/name/shido/testnet";

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response, forward }) => {
    if (graphQLErrors || networkError || response) {
      operation.query.definitions.forEach((definition) => {
        const op = "operation" in definition ? definition.operation : "";
        console.log(
          `[Operation Shido] apollo ${op} ${operation.operationName || ""}`
        );
      });
    }

    if (response) {
      console.log(`[Operation Result Shido] ${JSON.stringify(response)}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(async ({ message, locations, path }) => {
        const locationsStr = JSON.stringify(locations);
        console.warn(
          `[GraphQL Error Shido] Message: "${message}", Locations: ${locationsStr}, Path: "${path}"`
        );
        if (message === "jwt expired") {
          operation.setContext(({ headers = {} }: Record<string, any>) => ({
            headers: {
              ...headers,
            },
          }));
          return forward(operation);
        }
      });
    }

    if (networkError) {
      console.error(`[Network Error Shido] "${networkError}"`);
    }
  }
);

const httpLink = new HttpLink({
  uri: SERVER_URL_GQL,
});

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});

export const fetchRequestClient = async ({
  query,
  variables,
}: {
  query: DocumentNode | string;
  variables: OperationVariables;
}) => {
  try {
    const res = await fetch(SERVER_URL_GQL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    return await res.json();
  } catch (err) {
    return await Promise.reject(err);
  }
};

export default client;
