// shido testnet config
// ----------------------------------------------------------------
export const NFT_DESCRIPTOR_ADDRESS =
  "0x094B084Ff22C91a9EF0CFf4063a0825153a73BA2";
export const POSITION_DESCRIPTOR_ADDRESS =
  "0xbe7da4Cca3943b55af33351bdC872de6FD46fD0b";
export const POSITION_MANAGER_ADDRESS =
  "0xf6eFAf215638249782829EaaA87892Cc67ba351F";

// Token ERC20 Addresses
export const WSHIDO_ADDRESS = "0x2c9508639e6B901e2Bb1e51115b11A93eDc1CdD9";

export const FACTORY_ADDRESS = "0x392E5Ee7915dF1c3B97E2B89D7F6D70DD76Ce599";

export const QUOTER_CONTRACT_ADDRESS =
  "0xCe398E17A0d5dCE6D2b7Ae70642b80953E3D2c92";
export const SWAP_ROUTER_ADDRESS = "0x151255D1e03a631A1a25e8521841C1CF0F421aF0";

export const MULTI_CALL = "0xa29F6177f4B8d4b186501996F02c6b697f41b163";
export const TICK_LENS = "0xCE6c22CC4bE6186154a0077aebC0BB1517D2C8CE";
export const CHAIN_ID = 9007;

export const SWAP_ROUTER_V2 = "0x79F3C58BeDc49780dC3C4484888b5a891F612C93";

export const MAX_FEE_PER_GAS = 100000000000;
export const MAX_PRIORITY_FEE_PER_GAS = 100000000000;
